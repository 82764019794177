import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

const instance = createInstance({
	urlBase: 'https://analytics.creati.hu',
	siteId: 2,
	disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
	heartBeat: {
		// optional, enabled by default
		active: true, // optional, default value: true
		seconds: 10, // optional, default value: `15
	},
	linkTracking: true, // optional, default value: true
	configurations: {
		// optional, default value: {}
		// any valid matomo configuration, all below are optional
		disableCookies: true,
		setSecureCookie: true,
		setRequestMethod: 'POST',
	},
});

ReactDOM.render(
	<React.StrictMode>
		<MatomoProvider value={instance}>
			<App />
		</MatomoProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
